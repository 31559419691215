import React, { useEffect, useState } from 'react';
import MainNavigation from './MainNavigation';
import { WhatsappShareButton, EmailShareButton, ViberShareButton, WhatsappIcon, EmailIcon, ViberIcon  } from 'react-share';
import '../styles/EventsPage.css'; // Assurez-vous que le chemin d'accès est correct
import parse from 'html-react-parser'; // Ajoutez cette dépendance

const EventComponent = () => {
  const [referralID, setReferralID] = useState('');
  const [eventData, setEventData] = useState({
    title: '',
    date: '',
    time: '',
    location: { latitude: '', longitude: '' },
    message: ''
  });

  useEffect(() => {
    fetch(`${process.env.REACT_APP_API_URL}/api/events`)
      .then(response => response.json())
      .then(data => setEventData(data))
      .catch(error => console.error('Erreur lors de la récupération des données:', error));
      
      // Récupération du user_hash et appel API pour obtenir le referralID
    const userHash = localStorage.getItem("user_hash");
      if (userHash) {
        fetch(`${process.env.REACT_APP_API_URL}/api/users/referralID/${userHash}`)
          .then(response => response.json())
          .then(data => setReferralID(data.referralID))
          .catch(error => console.error('Erreur lors de la récupération du referralID:', error));
    }
  }, []);

  const handleOpenInMaps = () => {
    const { latitude, longitude } = eventData.location;
    window.open(`https://www.google.com/maps/search/?api=1&query=${latitude},${longitude}`, '_blank');
  };

  // Construction du lien de parrainage
  const referralLink = referralID ? `${process.env.REACT_APP_REFERRAL_URL}/signup?referralID=${referralID}` : '';
  
  // Formatage du message pour tenir compte des caractères en gras et retours à la lignes issus du json
  const formatMessage = (message) => {
    // Convertit les balises <b> en <strong> et \n en <br />, puis parse le résultat
    return parse(message.replace(/\n/g, '<br />').replace(/<b>(.*?)<\/b>/g, '<strong>$1</strong>'));
  };

  return (
    <div>
      <MainNavigation />
      <div className="event-header">
        <div className="event-header-content">
          <h1>{eventData.title}</h1>
          <p>{eventData.date}  {eventData.time}</p>
          {eventData.message && <h2>{formatMessage(eventData.message)}</h2>}
        </div>    
      </div>
        <div className="share-buttons-container">
            <WhatsappShareButton url={referralLink}><WhatsappIcon size={38} round={true} /></WhatsappShareButton>
            <EmailShareButton url={referralLink}><EmailIcon size={38} round={true} /></EmailShareButton>
            <ViberShareButton url={referralLink}><ViberIcon size={38} round={true} /></ViberShareButton>
        </div>
      {eventData.location.latitude && eventData.location.longitude && (
        <div className="event-main">
          <div className="map-container">
            <iframe
              width="600"
              height="450"
              style={{ border: 0 }}
              loading="lazy"
              allowFullScreen
              src={`https://www.google.com/maps/embed/v1/place?key=${process.env.REACT_APP_GOOGLE_MAPS_API_KEY}&language=en&q=${eventData.location.latitude},${eventData.location.longitude}`}>
            </iframe>
            <button onClick={handleOpenInMaps}>
              Get Directions
            </button>
          </div>
        </div>
      )}
    </div>
  );
};

export default EventComponent;

